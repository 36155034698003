var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.getters.can('create-call-notes'))?_c('v-btn',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" New Call Note ")]):_vm._e()]}}]),model:{value:(_vm.callNotesDialog),callback:function ($$v) {_vm.callNotesDialog=$$v},expression:"callNotesDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Create a Call Note ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('base-button',{attrs:{"other-style-value":"text-right","icon-bool":"","right":"","icon":"mdi-close"},on:{"clicked":function($event){_vm.callNotesDialog = false}}})],1)],1),_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Subject","error-messages":errors},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Body","error-messages":errors},model:{value:(_vm.noteBody),callback:function ($$v) {_vm.noteBody=$$v},expression:"noteBody"}})]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-right",attrs:{"disabled":invalid,"color":"primary","text":""},on:{"click":_vm.createNote}},[_vm._v(" Create ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }