<template>
  <v-card>
    <v-card-subtitle>
      <v-row class="pl-4">
        FAQ's
        <write-questions
          @updateQuestions="updateYardQuestions"
          :yard="yard"
        />
      </v-row>
    </v-card-subtitle>
    <v-row>
      <card-list-item :title="'Assigned Account Manager'">
        {{ yard.assigned_to_details | filterObject('username') }}
      </card-list-item>
      <card-list-item :title="'Reassign Check Ins Automatically'">
        {{ yard.assign_automatically }}
      </card-list-item>
      <card-list-item title="How many cars processed per month?">
        {{ yard.cars_processed_monthly | filterNA }}
      </card-list-item>
      <card-list-item title="Tier">
        {{ yard.tier | filterNA }}
      </card-list-item>
      <card-list-item title="What time of month do they sell?">
        {{ yard.time_of_month | monthQuarters | filterNA }}
      </card-list-item>
      <card-list-item title="What type of vehicles do they buy?">
        {{ yard.average_car_type | averageCarTypes | filterNA }}
      </card-list-item>
      <card-list-item title="How are they currently selling cats?">
        {{ yard.current_selling_method | sellingMethods | filterNA }}
      </card-list-item>
      <card-list-item title="Willing to ship?">
        {{ yard.purchasing_method | purchasingMethod | filterNA }}
      </card-list-item>
      <card-list-item title="Date to visit yard and price converters">
        {{ yard.pricing_date | formatDate }}
      </card-list-item>
      <card-list-item title="Who makes the decision to sell?">
        <ul>
          <li>
            Name: {{ yard.purchasing_contact | filterNA }}
          </li>
          <li>
            Number: {{ yard.purchasing_phone | filterNA }}
          </li>
          <li>
            E-Mail: {{ yard.purchasing_email | filterNA }}
          </li>
        </ul>
      </card-list-item>
    </v-row>
  </v-card>
</template>

<script>
import WriteQuestions from '../Write/WriteQuestions'
import { mapActions } from 'vuex'

export default {
  name: 'YardQuestions',
  components: { WriteQuestions },
  props: {
    yard: { type: Object, required: true }
  },
  methods: {
    ...mapActions({ updateYard: 'yardStore/updateYard' }),
    updateYardQuestions (params) {
      let body = { 'params': params, 'id': this.yard.id }
      this.updateYard(body).then(() => {
        this.$emit('updateYard')
      })
    }
  }
}
</script>
