<template>
  <v-container>
    <ValidationObserver
      v-slot="{ invalid }"
    >
      <v-form
        :value="invalid"
        @submit.prevent="addTerms"
      >
        <v-card
          class="ma-0"
          flat
        >
          <v-card-text>
            <div
              class="caption"
              v-if="creating"
            >
              All fields are <b>optional</b>
            </div>
            <v-card-subtitle
              v-if="editing"
              class="ml-1 mt-n3"
            >
              <v-row>
                Terms
                <base-button
                  color="error"
                  icon-bool
                  small
                  @clicked="$emit('cancel')"
                  icon="mdi-close-circle-outline"
                />
              </v-row>
            </v-card-subtitle>
            <v-row>
              <v-col>
                <v-select
                  v-model="volumeType"
                  :items="volumeTypes"
                  label="Minimum Volume"
                />
              </v-col>
              <v-col class="mr-4">
                <ValidationProvider
                  rules="decimal:0|max_value:999999"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-if="volumeType === 'UNT'"
                    v-model="volumeUnits"
                    label="Minimum Units"
                    type="number"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="decimal:0|max_value:999999"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-if="volumeType === 'LBS'"
                    v-model="volumeLbs"
                    label="Minimum lbs"
                    type="number"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4">
                <ValidationProvider
                  rules="decimal:2|max_value:100"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Pt Rate"
                    hint="Ex. 95.5"
                    v-model="ptRate"
                    append-icon="mdi-percent-outline"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4">
                <ValidationProvider
                  rules="decimal:2|max_value:100"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Pd Rate"
                    hint="Ex. 95.5"
                    v-model="pdRate"
                    append-icon="mdi-percent-outline"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4">
                <ValidationProvider
                  rules="decimal:2|max_value:100"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Rh Rate"
                    hint="Ex. 95.5"
                    v-model="rhRate"
                    append-icon="mdi-percent-outline"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <ValidationProvider
                  rules="decimal:2"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Treatment $/lb"
                    v-model="treatmentCharge"
                    prepend-icon="mdi-currency-usd"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <ValidationProvider
                  rules="decimal:2"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Handling Fee"
                    v-model="handlingFee"
                    prepend-icon="mdi-currency-usd"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <ValidationProvider
                  rules="decimal:2"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Finance Charge %"
                    v-model="financeChargePercent"
                    append-icon="mdi-percent"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <ValidationProvider
                  rules="max_value:9999|decimal:2|required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Shredding Fee Per Unit"
                    v-model="shreddingFee"
                    prepend-icon="mdi-currency-usd"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <ValidationProvider
                  rules="max_value:1|decimal:4|required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Brokerage Rate"
                    v-model="brokerageRate"
                    prepend-icon="mdi-currency-usd"
                    hint="Use two decimals 0.90"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="brokerageRateMode"
                    :items="$store.getters['getBrokerageRateModes']"
                    label="Brokerage Rate Target"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <p class="caption mb-0">
                  Payout Type
                </p>
                <v-switch
                  class="mt-0"
                  v-model="ouncePayoutType"
                  false-value="RE"
                  true-value="CO"
                  :label="(ouncePayoutType === 'CO') ? 'Contained oz' : 'Returned oz'"
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <p class="caption mb-0">
                  Freight Responsibility
                </p>
                <v-switch
                  class="mt-0"
                  v-model="freightResponsibility"
                  false-value="PG"
                  true-value="VE"
                  :label="(freightResponsibility === 'VE') ? 'Vendor' : 'PGM'"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <ValidationProvider
                  rules="max_value:999|decimal:0|required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Payment Days"
                    v-model="clientPaymentDays"
                    hint="Number of days for Payment"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Refining Charge per Returned Troy Oz
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4">
                <ValidationProvider
                  rules="decimal:2|max_value:999"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Pt Refining Charge"
                    hint="Ex. 10.3"
                    v-model="refiningChargePt"
                    prepend-inner-icon="mdi-currency-usd"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4">
                <ValidationProvider
                  rules="decimal:2|max_value:999"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Pd Refining Charge"
                    hint="Ex. 10.3"
                    v-model="refiningChargePd"
                    prepend-inner-icon="mdi-currency-usd"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4">
                <ValidationProvider
                  rules="decimal:2|max_value:999"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Rh Refining Charge"
                    hint="Ex. 10.3"
                    v-model="refiningChargeRh"
                    prepend-inner-icon="mdi-currency-usd"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4">
                <ValidationProvider
                  rules="decimal:2|max_value:9999"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Assay Charge"
                    v-model="assayCharge"
                    prepend-inner-icon="mdi-currency-usd"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-textarea
              label="Additional Terms"
              v-model="addtlTerms"
              rows="2"
              auto-grow
            />
            <v-textarea
              label="Description"
              v-model="description"
              rows="2"
              auto-grow
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              type="submit"
              color="primary"
            >
              Save
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
export default {
  name: 'WriteBrokerTerms',
  props: {
    creating: { type: Boolean, required: false, default: false },
    editing: { type: Boolean, required: false, default: false },
    brokerTerms: { type: Object, required: false, default: null },
    addingInfo: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    volumeTypes: [
      {
        text: 'Units',
        value: 'UNT'
      },
      {
        text: 'Pounds',
        value: 'LBS'
      }
    ],
    volumeType: 'UNT',
    volumeUnits: 0,
    volumeLbs: 0,
    refiningChargePt: 0,
    refiningChargePd: 0,
    refiningChargeRh: 0,
    assayCharge: 0,
    ptRate: null,
    pdRate: null,
    rhRate: null,
    treatmentCharge: null,
    financeChargePercent: null,
    handlingFee: null,
    ouncePayoutType: null,
    freightResponsibility: null,
    addtlTerms: null,
    description: null,
    shreddingFee: null,
    brokerageRate: null,
    brokerageRateMode: 'NA',
    clientPaymentDays: 0,
    id: null
  }),
  methods: {
    addTerms () {
      let params = {}
      if (this.volumeType) params.minimum_volume_type = this.volumeType
      if (!isNaN(this.volumeUnits)) params.minimum_volume_units = this.volumeUnits
      if (!isNaN(this.volumeLbs)) params.minimum_volume_lbs = this.volumeLbs
      if (!isNaN(this.refiningChargePt)) params.refining_charge_pt = this.refiningChargePt
      if (!isNaN(this.refiningChargePd)) params.refining_charge_pd = this.refiningChargePd
      if (!isNaN(this.refiningChargeRh)) params.refining_charge_rh = this.refiningChargeRh
      if (!isNaN(this.assayCharge)) params.assay_charge = this.assayCharge
      if (this.ptRate) params.pt_rate = this.ptRate
      if (this.pdRate) params.pd_rate = this.pdRate
      if (this.rhRate) params.rh_rate = this.rhRate
      if (this.treatmentCharge) params.treatment_charge = this.treatmentCharge
      if (this.financeChargePercent) params.finance_charge_percent = this.financeChargePercent
      if (this.handlingFee) params.handling_fee = this.handlingFee
      if (this.ouncePayoutType) params.ounce_payout_type = this.ouncePayoutType
      if (this.freightResponsibility) params.freight_responsibility = this.freightResponsibility
      if (this.addtlTerms) params.additional_terms = this.addtlTerms
      if (this.description) params.description = this.description
      if (this.shreddingFee) params.shredding_fee = this.shreddingFee
      if (this.brokerageRate) params.brokerage_rate = this.brokerageRate
      if (this.brokerageRateMode) params.brokerage_rate_mode = this.brokerageRateMode
      if (this.clientPaymentDays) params.client_payment_days = this.clientPaymentDays
      if (this.id) {
        params.id = this.id
        this.$emit('updateTerms', params)
      } else this.$emit('addTerms', params)
    },
    initData () {
      this.volumeType = this.brokerTerms.minimum_volume_type
      this.volumeUnits = this.brokerTerms.minimum_volume_units
      this.volumeLbs = this.brokerTerms.minimum_volume_lbs
      this.refiningChargePt = this.brokerTerms.refining_charge_pt
      this.refiningChargePd = this.brokerTerms.refining_charge_pd
      this.refiningChargeRh = this.brokerTerms.refining_charge_rh
      this.assayCharge = this.brokerTerms.assay_charge
      this.ptRate = this.brokerTerms.pt_rate
      this.pdRate = this.brokerTerms.pd_rate
      this.rhRate = this.brokerTerms.rh_rate
      this.treatmentCharge = this.brokerTerms.treatment_charge
      this.financeChargePercent = this.brokerTerms.finance_charge_percent
      this.handlingFee = this.brokerTerms.handling_fee
      this.ouncePayoutType = this.brokerTerms.ounce_payout_type
      this.freightResponsibility = this.brokerTerms.freight_responsibility
      this.addtlTerms = this.brokerTerms.additional_terms
      this.description = this.brokerTerms.description
      this.shreddingFee = this.brokerTerms.shredding_fee
      this.brokerageRate = this.brokerTerms.brokerage_rate
      this.brokerageRateMode = this.brokerTerms.brokerage_rate_mode
      this.clientPaymentDays = this.brokerTerms.client_payment_days
      this.id = this.brokerTerms.id
    }
  },
  mounted () {
    if (this.brokerTerms && this.editing) this.initData()
  }
}
</script>
