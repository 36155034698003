<template>
  <v-container>
    <v-card-subtitle
      v-if="editing"
      class="ml-1 mt-n3"
    >
      <v-row>
        Overview
        <base-button
          color="error"
          icon-bool
          small
          @clicked="$emit('cancel')"
          icon="mdi-close-circle-outline"
        />
      </v-row>
    </v-card-subtitle>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <div class="caption">
        * Required
      </div>
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.xs ? 12 : 6"
        >
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              label="Yard Name *"
              v-model="yardName"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 6 : 3">
          <v-select
            v-model="currency"
            :items="configCurrencies"
            :loading="configLoading"
            item-value="value"
            item-text="text"
            label="Currency"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 6 : 3">
          <v-checkbox
            label="Has dock"
            v-model="dock"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <paginated-autocomplete
            label="Select a Region"
            v-model="region"
            item-text="name"
            item-value="id"
            base-url="/api/regions/"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              label="Select a Status *"
              v-model="status"
              :items="statuses"
              item-text="text"
              item-value="value"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <paginated-autocomplete
            label="Select a PPM Calculator"
            v-model="ppmCalculator"
            item-text="name"
            item-value="id"
            base-url="/api/ppm_calculations/"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Street"
            v-model="address"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="City"
            v-model="city"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <ValidationProvider
            rules="max:2"
            v-slot="{ errors }"
          >
            <v-text-field
              label="State Abbreviation"
              :error-messages="errors"
              v-model="state"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Zip Code"
            v-model="zip"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Notes"
            v-model="notes"
            :rows="2"
            :counter="true"
            auto-grow
            maxlength="280"
          />
        </v-col>
      </v-row>
      <base-button
        auto-center
        :loading="addingInfo"
        :disabled="invalid"
        label="Submit"
        @clicked="addYardInfo"
      />
    </ValidationObserver>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'WriteYardInfo',
  props: {
    statuses: { type: Array, required: true },
    yard: { type: Object, required: false, default: null },
    editing: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    addingInfo: false,
    address: '',
    state: '',
    city: '',
    zip: '',
    notes: null,
    status: 'GS',
    yardName: null,
    region: null,
    previousRegion: null,
    dock: false,
    ppmCalculator: null,
    currency: null,
    currencyOptions: [],
    configLoading: false
  }),
  computed: {
    ...mapGetters([
      'configCurrencies',
      'configCurrenciesDefault'
    ])
  },
  methods: {
    // If yard, sets current info to be updated
    currentInfo () {
      this.yardName = this.yard.name
      this.address = this.yard.address
      this.city = this.yard.city
      this.state = this.yard.state
      this.zip = this.yard.zip_code
      this.region = this.yard.region
      this.previousRegion = this.yard.region
      this.status = this.yard.status
      if (this.yard.ppm_calculation) {
        this.ppmCalculator = this.yard.ppm_calculation.id
      }
      this.notes = this.yard.notes
      this.dock = this.yard.has_dock
      this.currency = this.yard.currency
    },
    async fetchConfig () {
      this.configLoading = true
      await this.$store.dispatch('fetchConfig')
      this.configLoading = false
      if (this.currency === null &&
        this.$store.getters.configCurrenciesDefault &&
        this.$store.getters.configCurrenciesDefault.value
      ) {
        this.currency = this.$store.getters.configCurrenciesDefault.value
      }
    },
    /**
     * Create or update; if/else is for emptied field check
     */
    addYardInfo () {
      this.addingInfo = true
      let params = {}
      if (this.yardName) params.name = this.yardName
      if (this.dock) params.has_dock = this.dock
      if (this.status) params.status = this.status
      if (this.notes) {
        params.notes = this.notes
      } else {
        params.notes = null
      }
      if (!this.editing) params.name = this.yardName
      if (this.address) params.address = this.address
      if (this.state) params.state = this.state
      if (this.city) params.city = this.city
      if (this.zip) params.zip_code = this.zip
      if (this.region && this.region !== this.previousRegion) params.region_id = this.region
      if (this.ppmCalculator) {
        params.ppm_calculation_id = this.ppmCalculator
      } else {
        params.ppm_calculation_id = null
      }
      if (this.currency) params.currency = this.currency
      this.$emit('addYardInfo', params)
      this.addingInfo = false
    }
  },
  mounted () {
    this.fetchConfig()
    if (this.yard) {
      this.currentInfo()
    }
  }
}
</script>
