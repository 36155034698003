<!--
Actually this should show CHECK IN HISTORY NOW
-->
<template>
  <v-card>
    <v-card-subtitle>
      Check-In History
    </v-card-subtitle>
    <v-expansion-panels
      v-if="yardCheckIns.length > 0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Total: {{ yardCheckIns.length }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="[
              {text: 'Check-In', value: 'id'},
              {text: 'Status', value: 'status'},
              {text: 'Created Date', value: 'created_at'}
            ]"
            :items="yardCheckIns"
          >
            <template v-slot:item.id="{ item }">
              <router-link :to="{ name: 'check-in', params: { id: item.id}}">
                {{ item.id }}
              </router-link>
            </template>
            <template v-slot:item.status="{ item }">
              {{ $store.getters['checkIn/getStatusesMethod'](item).text }}
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | formatDate }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <card-list-item
      :other-col-val="12"
      v-else
      title="This yard does not have any associated Check-Ins"
    />
  </v-card>
</template>

<script>
export default {
  name: 'YardCheckInHistory',
  props: { yardCheckIns: { type: Array, required: false, default: null } }
}
</script>
