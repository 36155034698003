<template>
  <v-card>
    <v-card-subtitle v-if="!editingInfo">
      <v-row class="pl-4">
        Hours
        <base-button
          color="info"
          icon-bool
          x-small
          @clicked="editingInfo = true"
          v-if="$store.getters.can('edit-yards')"
          icon="mdi-pencil"
        />
      </v-row>
    </v-card-subtitle>
    <div
      class="mt-n4"
      v-if="!editingInfo"
    >
      <v-simple-table v-if="hours && hours.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th> Day </th>
              <th> Closed </th>
              <th> Shipping </th>
              <th> Open </th>
              <th> Close </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(day, i) in filterDays"
              :key="i"
            >
              <td> {{ day.day | yardDays }} </td>
              <td> {{ day.is_closed | trueFalse }} </td>
              <td> {{ day.is_shipping | trueFalse }} </td>
              <td> {{ day.open_time | appendAMPM }} </td>
              <td> {{ day.close_time | appendAMPM }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <card-list-item
        :other-col-val="12"
        v-else
        title="This yard does not have hours added. Click pencil to add."
      />
    </div>
    <write-yard-hours
      v-else
      :days="days"
      :hours="hours"
      :loading="loading"
      @updateHours="updateHours"
      @addHours="addHours"
      @cancel="editingInfo = false"
    />
  </v-card>
</template>

<script>
import WriteYardHours from '../Write/WriteYardHours'

export default {
  name: 'YardHours',
  props: {
    hours: { type: Array, required: false, default: null },
    days: { type: Array, required: false, default: null },
    loading: { type: Boolean, required: true }
  },
  components: { WriteYardHours },
  data: () => ({
    editingInfo: false
  }),
  filters: {
    appendAMPM (obj) {
      if (obj) {
        let hour = obj.toString().split(':')
        if (hour[0] > '12') {
          hour[0] = hour[0] - 12
          return `${hour[0]}:${hour[1]} PM`
        } else if (hour[0] === '12') {
          return `${hour[0]}:${hour[1]} PM`
        } else {
          return `${hour[0]}:${hour[1]} AM`
        }
      } return '--'
    }
  },
  computed: {
    filterDays () {
      if (this.hours.length === 1) {
        return this.days.map(day => {
          const isShipping = day.value !== 'SU' && day.value !== 'SA'
          const isClosed = !isShipping
          const openTime = isShipping ? this.hours[0].open_time : null
          const closeTime = isShipping ? this.hours[0].close_time : null
          return {
            day: day.value,
            open_time: openTime,
            close_time: closeTime,
            is_shipping: isShipping,
            is_closed: isClosed
          }
        })
      } else {
        return this.hours.reduce((accu, hour) => {
          let index = 0
          for (let i = 0; i < this.days.length; i++) {
            if (this.days[i].value === hour.day) index = i
          }
          accu[index] = hour
          return accu
        }, [])
      }
    }
  },
  methods: {
    /**
     * Send the new hours from the child to the parent
     * @param {Object} body
     */
    addHours (body) {
      this.$emit('addHours', body)
    },
    /**
     * Send the updated hours from the child to the parent
     * @param {Object} body
     */
    updateHours (body) {
      this.$emit('updateHours', body)
    }
  }
}
</script>
