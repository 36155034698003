<template>
  <v-card>
    <v-card-subtitle>
      Call Notes
    </v-card-subtitle>
    <v-expansion-panels
      v-if="callNotes.length > 0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ callNotes.length }} {{ callNotes.length > 1 ? 'Notes' : 'Note' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="[
              {text: 'Date', value: 'created_at'},
              {text: 'Creator', value: 'created_by'},
              {text: 'Subject', value: 'subject'},
              {text: 'Body', value: 'body'}
            ]"
            :items="callNotes"
          >
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | formatDate }}
            </template>
            <template v-slot:item.created_by="{ item }">
              {{ item.created_by.username }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <card-list-item
      :other-col-val="12"
      v-else
      title="This yard does not have any call notes"
    />
  </v-card>
</template>

<script>
export default {
  name: 'CallNotes',
  props: {
    callNotes: { type: Array, required: false, default: null }
  }
}
</script>
