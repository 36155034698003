var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"value":invalid},on:{"submit":function($event){$event.preventDefault();return _vm.addTerms($event)}}},[_c('v-card',{staticClass:"ma-0",attrs:{"flat":""}},[_c('v-card-text',[(_vm.creating)?_c('div',{staticClass:"caption"},[_vm._v(" All fields are "),_c('b',[_vm._v("optional")])]):_vm._e(),(_vm.editing)?_c('v-card-subtitle',{staticClass:"ml-1 mt-n3"},[_c('v-row',[_vm._v(" Terms "),_c('base-button',{attrs:{"color":"error","icon-bool":"","small":"","icon":"mdi-close-circle-outline"},on:{"clicked":function($event){return _vm.$emit('cancel')}}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.volumeTypes,"label":"Minimum Volume"},model:{value:(_vm.volumeType),callback:function ($$v) {_vm.volumeType=$$v},expression:"volumeType"}})],1),_c('v-col',{staticClass:"mr-4"},[_c('ValidationProvider',{attrs:{"rules":"decimal:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.volumeType === 'UNT')?_c('v-text-field',{attrs:{"label":"Minimum Units","type":"number"},model:{value:(_vm.volumeUnits),callback:function ($$v) {_vm.volumeUnits=$$v},expression:"volumeUnits"}}):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"decimal:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.volumeType === 'LBS')?_c('v-text-field',{attrs:{"label":"Minimum lbs","type":"number","error-messages":errors},model:{value:(_vm.volumeLbs),callback:function ($$v) {_vm.volumeLbs=$$v},expression:"volumeLbs"}}):_vm._e()]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Pt Rate","hint":"Ex. 95.5","append-icon":"mdi-percent-outline","error-messages":errors},model:{value:(_vm.ptRate),callback:function ($$v) {_vm.ptRate=$$v},expression:"ptRate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Pd Rate","hint":"Ex. 95.5","append-icon":"mdi-percent-outline","error-messages":errors},model:{value:(_vm.pdRate),callback:function ($$v) {_vm.pdRate=$$v},expression:"pdRate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Rh Rate","hint":"Ex. 95.5","append-icon":"mdi-percent-outline","error-messages":errors},model:{value:(_vm.rhRate),callback:function ($$v) {_vm.rhRate=$$v},expression:"rhRate"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Treatment $/lb","prepend-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.treatmentCharge),callback:function ($$v) {_vm.treatmentCharge=$$v},expression:"treatmentCharge"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Handling Fee","prepend-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.handlingFee),callback:function ($$v) {_vm.handlingFee=$$v},expression:"handlingFee"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Finance Charge %","append-icon":"mdi-percent","error-messages":errors},model:{value:(_vm.financeChargePercent),callback:function ($$v) {_vm.financeChargePercent=$$v},expression:"financeChargePercent"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999|decimal:2|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Shredding Fee Per Unit","prepend-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.shreddingFee),callback:function ($$v) {_vm.shreddingFee=$$v},expression:"shreddingFee"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:1|decimal:4|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Brokerage Rate","prepend-icon":"mdi-currency-usd","hint":"Use two decimals 0.90","error-messages":errors},model:{value:(_vm.brokerageRate),callback:function ($$v) {_vm.brokerageRate=$$v},expression:"brokerageRate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.$store.getters['getBrokerageRateModes'],"label":"Brokerage Rate Target","error-messages":errors},model:{value:(_vm.brokerageRateMode),callback:function ($$v) {_vm.brokerageRateMode=$$v},expression:"brokerageRateMode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('p',{staticClass:"caption mb-0"},[_vm._v(" Payout Type ")]),_c('v-switch',{staticClass:"mt-0",attrs:{"false-value":"RE","true-value":"CO","label":(_vm.ouncePayoutType === 'CO') ? 'Contained oz' : 'Returned oz'},model:{value:(_vm.ouncePayoutType),callback:function ($$v) {_vm.ouncePayoutType=$$v},expression:"ouncePayoutType"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('p',{staticClass:"caption mb-0"},[_vm._v(" Freight Responsibility ")]),_c('v-switch',{staticClass:"mt-0",attrs:{"false-value":"PG","true-value":"VE","label":(_vm.freightResponsibility === 'VE') ? 'Vendor' : 'PGM'},model:{value:(_vm.freightResponsibility),callback:function ($$v) {_vm.freightResponsibility=$$v},expression:"freightResponsibility"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:999|decimal:0|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Payment Days","hint":"Number of days for Payment","error-messages":errors},model:{value:(_vm.clientPaymentDays),callback:function ($$v) {_vm.clientPaymentDays=$$v},expression:"clientPaymentDays"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_vm._v(" Refining Charge per Returned Troy Oz ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2|max_value:999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Pt Refining Charge","hint":"Ex. 10.3","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.refiningChargePt),callback:function ($$v) {_vm.refiningChargePt=$$v},expression:"refiningChargePt"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2|max_value:999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Pd Refining Charge","hint":"Ex. 10.3","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.refiningChargePd),callback:function ($$v) {_vm.refiningChargePd=$$v},expression:"refiningChargePd"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2|max_value:999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Rh Refining Charge","hint":"Ex. 10.3","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.refiningChargeRh),callback:function ($$v) {_vm.refiningChargeRh=$$v},expression:"refiningChargeRh"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('ValidationProvider',{attrs:{"rules":"decimal:2|max_value:9999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Assay Charge","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.assayCharge),callback:function ($$v) {_vm.assayCharge=$$v},expression:"assayCharge"}})]}}],null,true)})],1)],1),_c('v-textarea',{attrs:{"label":"Additional Terms","rows":"2","auto-grow":""},model:{value:(_vm.addtlTerms),callback:function ($$v) {_vm.addtlTerms=$$v},expression:"addtlTerms"}}),_c('v-textarea',{attrs:{"label":"Description","rows":"2","auto-grow":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v(" Save ")]),_c('v-spacer')],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }