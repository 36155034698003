<template>
  <parent-tabs :tabs="filterTabs()" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'YardParent',
  methods: {
    filterTabs () {
      return this.pageTabs.reduce((accu, i) => {
        if (i.can) accu.push(i)
        return accu
      }, [])
    }
  },
  computed: {
    ...mapGetters({
      can: 'can'
    }),
    pageTabs () {
      return [
        { id: 1, title: 'List', route: `/yard-list`, can: this.can('view-yards') },
        { id: 2, title: 'Create Yard', route: '/create-yard', can: this.can('create-yards') }
      ]
    }
  }
}
</script>
