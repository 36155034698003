<template>
  <unauthorized
    :authorized="$store.getters.can('view-yards')"
    message="Not Authorized to View Yard"
  >
    <v-container>
      <url-breadcrumbs
        v-if="yard"
        :items="[
          ['yard list', { name: 'yard-list'}],
          yard.url
        ]"
      />
      <base-title v-if="!loading">
        {{ yard.name }}
      </base-title>
      <app-loading-indicator v-if="loading" />
      <div v-else>
        <v-toolbar
          flat
          dense
        >
          <user-upload-dialog
            :file-links="fileLinks"
          />
          <v-spacer />
          <write-call-notes
            :id="yard.id"
            @refetchYard="fetchRequirements"
          />
        </v-toolbar>
        <yard-info
          :statuses="statuses"
          :yard="yard"
          @updateYard="updateYardWrapper"
        />
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <contact-info
              @updateYard="updateYardWrapper"
              :yard="yard"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <yard-hours
              :hours="yard.yard_hours"
              :days="yardDays"
              :loading="updatingHours"
              @addHours="addHours"
              @updateHours="updateHours"
            />
            <br>
            <broker-terms
              :broker-terms="brokerageterm"
              @updateTerms="updateTerms"
              @addTerms="addTerms"
              @updated="fetchRequirements"
            />
          </v-col>
        </v-row>
        <yard-questions
          @updateYard="fetchRequirements"
          :yard="yard"
        />
        <br>
        <call-notes :call-notes="yard.call_notes" />
        <br>
        <yard-check-in-history :yard-check-ins="yardCheckIns" />
      </div>
    </v-container>
  </unauthorized>
</template>

<script>
import YardInfo from './Read/YardInfo'
import ContactInfo from './Read/ContactInfo'
import BrokerTerms from './Read/BrokerTerms'
import YardQuestions from './Read/YardQuestions'
import YardHours from './Read/YardHours'
import YardCheckInHistory from './Read/YardCheckInHistory'
import CallNotes from './Read/CallNotes'
import WriteCallNotes from './Write/WriteCallNotes'
import { mapGetters, mapActions } from 'vuex'
import UserUploadDialog from '@/base/components/UserUploadDialog.vue'
import fileLinks from '@/helpers/fileLinks'

export default {
  name: 'Yard',
  components: {
    UserUploadDialog,
    YardInfo,
    ContactInfo,
    BrokerTerms,
    YardQuestions,
    YardHours,
    YardCheckInHistory,
    CallNotes,
    WriteCallNotes
  },
  data: () => ({
    loading: true,
    updatingHours: false
  }),
  methods: {
    ...mapActions('yardStore', [
      'fetchBrokerageTerms',
      'addBrokerageTerms',
      'updateBrokerageTerms',
      'fetchYard',
      'updateYard',
      'addYardHours',
      'updateYardHours'
    ]),
    ...mapActions('checkIn', ['fetchCheckIns']),
    /**
     * Get the yard after updates are completed
     * @return {Promise}
     */
    refetchYard () {
      return this.fetchYard(this.$route.params.id)
    },
    /**
     * Fetch all required entities for the page to load correctly. Set loading to false when done
     */
    async fetchRequirements () {
      await Promise.all([
        this.fetchYard(this.$route.params.id),
        this.fetchCheckIns({ yard__id: this.$route.params.id })
      ])
      // load the brokerage terms
      if (this.yard.brokerageterm) {
        const url = (typeof this.yard.brokerageterm === 'string') ? this.yard.brokerageterm : this.yard.brokerageterm.url
        await this.fetchBrokerageTerms(url)
      } else {
        this.$store.commit('yardStore/setTerms', {})
      }
      this.loading = false
    },
    /**
     * Update information directly related to the yard
     * @param {Object} params
     */
    updateYardWrapper (params) {
      this.updateYard({ id: this.yard.id, params }).then(() => { this.refetchYard() })
    },
    /**
     * Update brokerage terms for the given yard
     * @param {Object} params
     */
    updateTerms (params) {
      params.yard = this.yard.url
      this.updateBrokerageTerms(params).then(() => { this.refetchYard() })
    },
    /**
     * Add brokerage terms to the given yard
     * @param {Object} params
     */
    addTerms (params) {
      params.yard = this.yard.url
      this.addBrokerageTerms(params).then(() => { this.refetchYard() })
    },
    /**
     * Update the current yard's hours
     * @param {Object} body
     */
    updateHours (body) {
      this.updatingHours = true
      this.updateYardHours({ ...body, yardId: this.yard.id }).then(() => {
        this.refetchYard().then(() => { this.updatingHours = false })
      })
    },
    /**
     * Create a new entry for yard hours
     * @param {Object} body
     */
    addHours (body) {
      this.updatingHours = true
      this.addYardHours({ ...body, yardId: this.yard.id }).then(() => {
        this.refetchYard().then(() => { this.updatingHours = false })
      })
    }
  },
  computed: {
    ...mapGetters({
      yardCheckIns: 'checkIn/checkIns',
      yardDays: 'yardStore/yardDays',
      yard: 'yardStore/yard',
      statuses: 'businessStatuses',
      brokerageterm: 'yardStore/brokerageTerms'
    }),
    fileLinks () {
      return fileLinks.yardFileLink(this.yard)
    }
  },
  mounted () {
    this.fetchRequirements()
  }
}
</script>
