<template>
  <div>
    <v-skeleton-loader
      v-if="fetching"
      type="list-item-avatar-three-line@3"
    />
    <p
      class="text-center"
      v-else-if="attachedFiles.length === 0"
    >
      <em>
        No files to show
      </em>
    </p>
    <v-list
      v-else
      dense
    >
      <v-list-item
        v-for="file in attachedFiles"
        :key="file.id"
        @click="openFile(file)"
      >
        <v-list-item-avatar
          tile
          color="primary"
          height="80"
          width="120"
          class="text-center"
        >
          <v-img
            v-if="previewTypes.includes(file.content_type)"
            :src="file.upload"
          />
          <v-icon
            v-else
            color="white"
          >
            mdi-cloud-download-outline
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ file.filename }}
          </v-list-item-title>
          <p class="text-left">
            <small>
              uploaded by {{ file.created_by__username }} on {{ file.created_at | formatDate }}
            </small>
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UserUploadList',
  props: {
    /**
     * Assignments should contain { searchName: Array<int>  }
     * These will be used to lookup files using the key and ids
     */
    fileLinks: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fetching: false,
    attachedFiles: [],
    previewTypes: [
      '.jpg',
      '.jpeg',
      '.gif',
      '.png'
    ],
    fetchParamsString: null
  }),
  mounted () {
    this.fetchAttachedFiles()
    this.$root.$on('newFilesList', (fetchParamsString) => {
      if (fetchParamsString === this.fetchParamsString) {
        this.fetchAttachedFiles()
      }
    })
  },
  methods: {
    /**
     * Fetches attached files so we have file links
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchAttachedFiles () {
      this.fetching = true
      return axios.get('/api/user_uploads/', { params: this.getFetchParams() }).then(r => {
        if (r.status === 200) {
          this.attachedFiles = r.data
        }
      }).finally(() => {
        this.fetching = false
      })
    },
    /**
     * Formats the fetch params to feed to UserUpload api
     */
    getFetchParams () {
      let params = { page_size: 0 }
      for (const key in this.fileLinks) {
        const v = this.fileLinks[key]
        // wraps variable in array if one already
        params[key + '__in'] = Array.isArray(v) ? v.join(',') : v
      }
      this.fetchParamsString = JSON.stringify(params)
      return params
    }
  }
}
</script>
