<template>
  <unauthorized
    :authorized="$store.getters.can('create-yards')"
    message="Not Authorized to Create Yards"
  >
    <base-title title="Create Yard" />
    <v-stepper
      alt-labels
      v-model="step"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Add Yard Info
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Add Terms
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          Add Hours
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 4"
          step="4"
        >
          Add Contact Info
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <write-yard-info
            :statuses="statuses"
            @addYardInfo="createNewYard"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <write-broker-terms
            :adding-info="addingContactInfo"
            :creating="creating"
            @addTerms="addTerms"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <write-yard-hours
            :days="yardDays"
            :loading="addingHours"
            @addHours="addHours"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <write-contact-info
            :loading="addingContactInfo"
            @addContactInfo="updateYardContact"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </unauthorized>
</template>

<script>
import WriteYardInfo from './Write/WriteYardInfo'
import WriteBrokerTerms from './Write/WriteBrokerTerms'
import WriteYardHours from './Write/WriteYardHours'
import WriteContactInfo from './Write/WriteContactInfo'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateYard',
  components: {
    WriteYardInfo,
    WriteYardHours,
    WriteContactInfo,
    WriteBrokerTerms
  },
  data: () => ({
    step: 1,
    id: null,
    prevNumOrgs: 0,
    orgInfiniteDistance: 0,
    addingContactInfo: false,
    creating: true,
    yard: null,
    addingHours: false
  }),
  methods: {
    ...mapActions('yardStore', [
      'addBrokerageTerms',
      'createYard',
      'updateYard',
      'addYardHours'
    ]),
    /**
     * Create a new yard
     * @param {Object} body
     */
    createNewYard (body) {
      this.createYard(body).then((res) => {
        if (res) {
          this.id = res.id
          this.yard = res
          this.step++
        }
      })
    },
    addTerms (params) {
      this.addingContactInfo = true
      if (Object.keys(params).length !== 0) {
        params.yard = this.yard.url
        this.addBrokerageTerms(params).then(() => { this.step++ })
      } else this.step++
    },
    /**
     * Add yard hours to the given yard
     * @param {Object} body
     */
    addHours (body) {
      this.addingHours = true
      body.yardId = this.yard.id
      this.addYardHours(body).then(() => {
        this.addingHours = false
        this.step++
      })
    },
    /**
     * Update a yard
     * @param {Object} params
     */
    updateYardContact (params) {
      this.addingContactInfo = true
      this.updateYard({ params, id: this.id }).then((id) => {
        if (id) this.$router.push({ name: 'yard', params: { id: this.id } })
        this.addingContactInfo = false
      })
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'businessStatuses',
      yardDays: 'yardStore/yardDays'
    })
  }
}
</script>
