<template>
  <v-container>
    <v-card-subtitle
      v-if="hours"
      class="ml-1 mt-n3"
    >
      <v-row>
        Hours
        <base-button
          color="error"
          icon-bool
          small
          @clicked="$emit('cancel')"
          icon="mdi-close-circle-outline"
        />
      </v-row>
    </v-card-subtitle>
    <v-skeleton-loader
      type="table-tbody"
      v-if="initializing"
    />
    <ValidationObserver
      v-slot="{ invalid }"
      v-else
      ref="validationObserver"
    >
      <v-checkbox
        label="Work Week"
        color="primary"
        v-model="ww"
      />
      <v-simple-table v-if="!ww">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.order"
              >
                {{ header.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(weekDay, i) in week"
              :key="i"
            >
              <td> {{ weekDay.day }} </td>
              <td>
                <v-checkbox
                  v-model="weekDay.is_closed"
                  :disabled="weekDay.is_shipping"
                />
              </td>
              <td>
                <v-checkbox
                  v-model="weekDay.is_shipping"
                  :disabled="weekDay.is_closed"
                />
              </td>
              <td>
                <time-input
                  v-model="weekDay.open_time"
                  :max="weekDay.close_time"
                  :disabled="weekDay.is_closed"
                />
              </td>
              <td>
                <time-input
                  v-model="weekDay.close_time"
                  :min="weekDay.open_time"
                  :disabled="weekDay.is_closed"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row v-else>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <time-input
              v-model="openTime"
              label="Open"
              :max="closeTime"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <time-input
              v-model="closeTime"
              label="Close"
              :min="openTime"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <base-button
        v-if="hours"
        auto-center
        label="Save"
        :disabled="loading || invalid"
        :loading="loading"
        @clicked="updateYardHours"
      />
      <base-button
        v-else
        auto-center
        label="Submit"
        :disabled="invalid || loading"
        :loading="loading"
        @clicked="addYardHours"
      />
    </ValidationObserver>
  </v-container>
</template>

<script>
export default {
  name: 'WriteYardHours',
  props: {
    days: { type: Array, required: true },
    hours: { type: Array, required: false, default: null },
    loading: { type: Boolean, required: true }
  },
  data: function () {
    const week = this.initializeWeekDays()
    return {
      wasLoading: false,
      week,
      ww: false,
      wwId: null,
      openTime: null,
      closeTime: null,
      initializing: true,
      headers: [
        { label: 'Day', order: 1 },
        { label: 'Closed', order: 2 },
        { label: 'Shipping', order: 3 },
        { label: 'Open', order: 4 },
        { label: 'Close', order: 5 }
      ]
    }
  },
  watch: {
    loading () {
      if (this.loading) this.wasLoading = true
      if (this.wasLoading && !this.loading) this.$emit('cancel')
    }
  },
  methods: {
    /**
     * Set the initial values for the yard hours if they are supplied
     */
    initializeYardHours () {
      if (!this.hours || this.hours.length < 1) return
      if (this.hours.length === 1) {
        const wwEntry = this.hours[0]
        this.ww = true
        this.wwId = wwEntry.id
        this.openTime = wwEntry.open_time
        this.closeTime = wwEntry.close_time
        this.week = this.days.map(day => {
          const isShipping = day.value !== 'SU' && day.value !== 'SA'
          const isClosed = !isShipping
          const openTime = isShipping ? wwEntry.open_time : null
          const closeTime = isShipping ? wwEntry.close_time : null
          return {
            day: day.value,
            open_time: openTime,
            close_time: closeTime,
            is_shipping: isShipping,
            is_closed: isClosed
          }
        })
      } else {
        this.week = this.hours.filter(hour => hour.day !== 'WW').reduce((accu, hour) => {
          let index = 0
          for (let i = 0; i < this.days.length; i++) {
            if (this.days[i].value === hour.day) index = i
          }
          accu[index] = hour
          return accu
        }, [])
      }
    },
    /**
     * Set the initial value for the week
     */
    initializeWeekDays () {
      return this.days.filter(day => day.value !== 'WW').map(day => {
        return {
          day: day.value,
          is_shipping: false,
          is_closed: false,
          open_time: null,
          close_time: null
        }
      })
    },
    /**
     * Tell the parent to create yard hours
     */
    addYardHours () {
      let body = { week: this.week }
      if (this.ww) body = { ww: true, open_time: this.openTime, close_time: this.closeTime }
      this.$emit('addHours', body)
    },
    /**
     * Tell the parent to update the yard hours
     */
    updateYardHours () {
      if (!this.hours || this.hours.length < 1) return this.addYardHours()
      let body = { week: this.week }
      if (this.ww) body = { ww: true, wwId: this.wwId, open_time: this.openTime, close_time: this.closeTime }
      this.$emit('updateHours', body)
    }
  },
  mounted () {
    this.initializeYardHours()
    this.initializing = false
  }
}
</script>
