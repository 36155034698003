<template>
  <unauthorized
    :authorized="$store.getters.can('view-yards')"
    message="Not Authorized to view Yards"
  >
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <date-range-input
        :start-date="startDate"
        :end-date="endDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="startDate = $event"
        @alterEndDate="endDate = $event"
      />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-select
            label="Statuses"
            multiple
            clearable
            v-model="status"
            :items="$store.getters['getBusinessStatus']"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Address"
            v-model="address"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="City"
            v-model="city"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-autocomplete
            label="States"
            v-model="states"
            multiple
            clearable
            :items="$store.getters['getStateList']"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Zip Code"
            v-model="zipCode"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Region Name"
            v-model="region"
          />
        </v-col>
        <v-col />
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="yard"
        />
      </template>
      <template v-slot:item.state="{ item }">
        {{ item.state | filterState }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-yards')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      :to="{ name: 'create-yard' }"
    >
      Create Yard
    </text-fab>
  </unauthorized>
</template>

<script>
import { mapGetters } from 'vuex'
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import axios from 'axios'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'

export default {
  mixins: [chipsMixin, paginationMixin],
  name: 'YardList',
  components: {
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'yard-list',

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Currency', value: 'currency' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'State', value: 'state' },
        { text: 'Status', value: 'status' },
        { text: 'Created', value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'Address', prop: 'address', type: String, query: 'address__icontains' },
        { name: 'City', prop: 'city', type: String, query: 'city__icontains' },
        { name: 'States', prop: 'states', type: Array, query: 'state__in' },
        { name: 'zipCode', prop: 'zipCode', type: String, query: 'zipCode' },
        { name: 'region', prop: 'region', type: String, query: 'region__name__icontains' },
        { name: 'Status', prop: 'status', type: Array, query: 'status__in' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated, previous) {
      this.routePage(updated)
    }
  },
  computed: {
    ...mapGetters({
      yardStatuses: 'businessStatuses'
    })
  },
  methods: {
    // Creates and submits query parameters
    async executeSearch () {
      // eslint-disable-next-line no-undef
      const params = this.getQueryParams()
      axios.get('/api/yards/', { params }).then(this.setResponseResults)
    }
  },
  filters: {
    filterState (state) {
      if (state) {
        return state.toUpperCase()
      }
      return '--'
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
