<template>
  <v-dialog
    v-model="questionDialog"
    fullscreen
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        x-small
        icon
        color="info"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Questions
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <base-button
            @clicked="questionDialog = false"
            other-style-value="text-right"
            icon-bool
            right
            icon="mdi-close"
          />
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <ul>
            <li>
              <search-autocomplete
                v-model="assignedTo"
                :params="{ fields: ['username', 'url'].join(','), ordering: 'username', groups__name: 'account-manager', is_active: true }"
                label="Assigned Account Manager. Type name to search."
                api-path="/api/users/"
                :format-entry="x => ({ friendlyName: x.username, url: x.url, username: x.username })"
              />
            </li>
            <li>
              <v-switch
                v-model="assignAutomatically"
                label="Auto Assign Check In's to Assigned User"
              />
            </li>
            <li class="pt-4">
              <v-text-field
                label="How many vehicles do you process per month?"
                type="Number"
                hint="Enter a number"
                v-model="carsProcessed"
              />
            </li>
            <li class="pt-4">
              <v-select
                label="What time of month do you sell?"
                :items="monthQuarters"
                item-text="text"
                item-value="value"
                v-model="timeOfMonth"
              />
            </li>
            <li class="pt-4">
              <v-select
                v-model="averageCarType"
                :items="averageCarTypes"
                item-text="text"
                item-value="value"
                label="What type of vehicle do you buy?"
              />
            </li>
            <li class="pt-4">
              <v-select
                v-model="sellingMethod"
                :items="sellingMethods"
                item-text="text"
                item-value="value"
                label="How are you selling catalytic converters now?"
              />
            </li>
            <li class="pt-4">
              <v-select
                v-model="purchasingMethod"
                :items="purchasingMethods"
                item-text="text"
                item-value="value"
                label="Are you willing to ship?"
              />
            </li>
            <li class="pt-4">
              <date-input
                v-model="pricingDate"
                label="Are we able to set up a date to price catalytic converters?"
              />
            </li>
            <li>
              <v-list-item-subtitle>
                Who makes the decision to sell?
              </v-list-item-subtitle>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Purchasing Name (Optional)"
                    v-model="purchasingName"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Purchasing Number (Optional)"
                    v-model="purchasingNumber"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Purchasing E-mail"
                    v-model="purchasingEmail"
                  />
                </v-col>
              </v-row>
            </li>
          </ul>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              class="text-right"
              :disabled="invalid"
              @click="saveQuestions"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import { mapState } from 'vuex'

export default {
  name: 'WriteQuestions',
  components: {
    SearchAutocomplete
  },
  props: { yard: { type: Object, required: true } },
  data: () => ({
    questionDialog: false,
    assignedTo: null,
    contact: null,
    carsProcessed: 0,
    salesFrequency: null,
    averageCarType: null,
    sellingMethod: null,
    purchasingMethod: null,
    pricingDate: null,
    frequency: 'WEEKLY',
    interval: 1,
    dayOfWeek: null,
    timeOfMonth: null,
    monthNo: 0,
    dayOfMonth: null,
    rulesInterval: [
      x => x >= 0 || 'must be a positive number',
      x => x < 150 || 'interval is too high'
    ],
    rulesMonth: [
      x => x >= 0 || 'must be a positive number',
      x => x <= 32 || 'Must choose a number less than 31'
    ],
    initializing: false,
    purchasingName: null,
    purchasingNumber: null,
    purchasingEmail: null
  }),
  watch: {
    // reset every time frequency is changed
    frequency () {
      if (!this.initializing) {
        this.dayOfWeek = null
        this.monthNo = 0
        this.dayOfMonth = 0
        this.salesFrequency = null
      }
    },
    carsProcessed () {
      if (this.carsProcessed === '' || isNaN(this.carsProcessed)) {
        this.carsProcessed = 0
      }
    }
  },
  methods: {
    saveQuestions () {
      let body = {
        'assigned_to': this.assignedTo ? this.assignedTo['url'] : null,
        'assign_automatically': this.assignAutomatically,
        'cars_processed_monthly': this.carsProcessed,
        'sales_frequency': this.salesFrequency,
        'average_car_type': this.averageCarType,
        'pricing_date': this.pricingDate,
        'current_selling_method': this.sellingMethod,
        'purchasing_method': this.purchasingMethod,
        'time_of_month': this.timeOfMonth,
        'purchasing_contact': this.purchasingName,
        'purchasing_phone': this.purchasingNumber,
        'purchasing_email': this.purchasingEmail
      }
      this.$emit('updateQuestions', body)
      this.questionDialog = false
    },
    setInitData () {
      if (this.yard.purchasing_contact) this.purchasingName = this.yard.purchasing_contact
      if (this.yard.assign_automatically) this.assignAutomatically = this.yard.assign_automatically
      if (this.yard.purchasing_phone) this.purchasingNumber = this.yard.purchasing_phone
      if (this.yard.purchasing_email) this.purchasingEmail = this.yard.purchasing_email
      if (this.yard.current_selling_method) this.sellingMethod = this.yard.current_selling_method
      if (this.yard.pricing_date) this.pricingDate = this.yard.pricing_date
      if (this.yard.cars_processed_monthly) this.carsProcessed = this.yard.cars_processed_monthly
      if (this.yard.average_car_type) this.averageCarType = this.yard.average_car_type
      if (this.yard.purchasing_method) this.purchasingMethod = this.yard.purchasing_method
      if (this.yard.assigned_to) this.assignedTo = { friendlyName: this.yard.assigned_to_details.username, url: this.yard.assigned_to_details.url, username: this.yard.assigned_to_details.username }
      if (this.yard.time_of_month) this.timeOfMonth = this.yard.time_of_month
    }
  },
  computed: {
    ...mapState('yardStore', {
      weekDays: 'yardDays',
      sellingMethods: 'sellingMethods',
      purchasingMethods: 'purchasingMethods',
      averageCarTypes: 'averageCarTypes',
      months: 'yardMonths',
      monthQuarters: 'monthQuarters'
    })
  },
  mounted () {
    this.setInitData()
  }
}
</script>
