<template>
  <v-card>
    <v-card-subtitle v-if="!editingInfo">
      <v-row class="pl-4">
        Overview
        <base-button
          color="info"
          icon-bool
          x-small
          @clicked="editingInfo = true"
          v-if="$store.getters.can('edit-yards')"
          icon="mdi-pencil"
        />
      </v-row>
    </v-card-subtitle>
    <div
      class="ml-4 mt-n4"
      v-if="!editingInfo"
    >
      <v-row>
        <card-list-item title="Name">
          {{ yard.name | filterNA | yardStatus }}
        </card-list-item>
        <card-list-item title="Currency">
          {{ yard.currency_text }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item title="Status">
          {{ yard.status | filterNA | yardStatus }}
        </card-list-item>
        <card-list-item title="Has dock">
          {{ yard.has_dock | trueFalse }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item title="Region">
          {{ yard.region | filterObject('name') }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          v-if="yard.ppm_calculation"
          title="PPM Calculator"
        >
          <router-link
            :to="{ name: 'ppm-calculation', params: { id: yard.ppm_calculation.id } }"
          >
            {{ yard.ppm_calculation.name }}
          </router-link>
        </card-list-item>
        <card-list-item
          title="PPM Calculator"
          v-else
        >
          Not Added
        </card-list-item>
        <card-list-item title="Address">
          {{ yard | filterAddress | filterNA }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          :other-col-val="12"
          title="Notes"
        >
          {{ yard.notes | filterNA }}
        </card-list-item>
      </v-row>
    </div>
    <write-yard-info
      v-else
      :editing="true"
      :statuses="statuses"
      :hours="yard.yard_hours"
      :yard="yard"
      @addYardInfo="updateYard"
      @cancel="editingInfo = false"
    />
  </v-card>
</template>

<script>
import WriteYardInfo from '../Write/WriteYardInfo'

export default {
  name: 'YardInfo',
  props: {
    yard: { type: Object, required: true },
    statuses: { type: Array, required: true }
  },
  components: { WriteYardInfo },
  data: () => ({ editingInfo: false }),
  filters: {
    filterAddress (yard) {
      let address = ''
      if (yard.address) address += `${yard.address}`
      if (yard.city) address += ` ${yard.city}`
      if (yard.state && yard.city) address += `, ${yard.state}`
      else if (yard.state) address += ` ${yard.state}`
      if (yard.zip_code) address += ` ${yard.zip_code}`
      return address
    }
  },
  methods: {
    /**
     * Tell the parent element to update the yard
     */
    updateYard (params) {
      this.editingInfo = false
      this.$emit('updateYard', params)
    }
  }
}
</script>
