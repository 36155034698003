<template>
  <v-container>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <v-card-subtitle
        v-if="editing"
        class="ml-1 mt-n3"
      >
        <v-row>
          Contacts
          <base-button
            color="error"
            icon-bool
            small
            @clicked="$emit('cancel')"
            icon="mdi-close-circle-outline"
          />
        </v-row>
      </v-card-subtitle>
      <div class="caption">
        * Required
      </div>
      <v-card-subtitle class="font-weight-bold">
        Primary Contact
      </v-card-subtitle>
      <ValidationProvider
        rules="required|max:30"
        v-slot="{ errors }"
      >
        <v-text-field
          label="Name *"
          v-model="pName"
          :error-messages="formatErrors(errors)"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="required|max:30"
        v-slot="{ errors }"
      >
        <v-text-field
          label="Number *"
          v-model="pNumber"
          :error-messages="formatErrors(errors)"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="email"
        v-slot="{ errors }"
      >
        <v-text-field
          label="E-mail"
          v-model="pEmail"
          :error-messages="errors"
        />
      </ValidationProvider>
      <v-checkbox
        v-if="!yard"
        label="Shipping Contact same as Primary Contact"
        v-model="sameDetails"
      />
      <v-card-subtitle class="font-weight-bold">
        Shipping Contact
      </v-card-subtitle>
      <ValidationProvider
        rules="required|max:30"
        v-slot="{ errors }"
      >
        <v-tooltip
          bottom
          :disabled="!sameDetails"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-text-field
                :disabled="sameDetails"
                label="Name"
                v-model="sName"
                :error-messages="formatErrors(errors)"
              />
            </span>
          </template>
          <span> The Shipping &amp; Contact name will be the same </span>
        </v-tooltip>
      </ValidationProvider>
      <ValidationProvider
        rules="required|max:30"
        v-slot="{ errors }"
      >
        <v-tooltip
          bottom
          :disabled="!sameDetails"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-text-field
                :disabled="sameDetails"
                label="Number"
                v-model="sNumber"
                :error-messages="formatErrors(errors)"
              />
            </span>
          </template>
          <span> The Shipping &amp; Contact number will be the same </span>
        </v-tooltip>
      </ValidationProvider>
      <ValidationProvider
        rules="email"
        v-slot="{ errors }"
      >
        <v-tooltip
          bottom
          :disabled="!sameDetails"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-text-field
                :disabled="sameDetails"
                label="E-mail"
                v-model="sEmail"
                :error-messages="errors"
              />
            </span>
          </template>
          <span> The Shipping &amp; Contact e-mail will be the same </span>
        </v-tooltip>
      </ValidationProvider>
      <base-button
        auto-center
        label="Submit"
        :disabled="invalid"
        @clicked="addContactInfo"
      />
    </ValidationObserver>
  </v-container>
</template>

<script>
export default {
  name: 'WriteContactInfo',
  props: {
    loading: { type: Boolean, required: false, default: false },
    yard: { type: Object, required: false, default: null },
    editing: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    pName: null,
    pEmail: null,
    pNumber: null,
    sName: null,
    sEmail: null,
    sNumber: null,
    sameDetails: false
  }),
  methods: {
    formatErrors (errors) {
      const error = errors[0]
      if (error === 'max') return 'Must be less than 30 characters'
      return error
    },
    addContactInfo () {
      const params = {
        contact: this.pName,
        email: this.pEmail,
        phone: this.pNumber
      }
      if (this.sameDetails) {
        params.shipping_contact = this.pName
        params.shipping_phone = this.pNumber
        params.shipping_email = this.pEmail
      } else {
        params.shipping_contact = this.sName
        params.shipping_phone = this.sNumber
        params.shipping_email = this.sEmail
      }
      this.$emit('addContactInfo', params)
    },
    currentInfo () {
      this.pName = this.yard.contact
      this.pEmail = this.yard.email
      this.pNumber = this.yard.phone
      this.sName = this.yard.shipping_contact
      this.sEmail = this.yard.shipping_email
      this.sNumber = this.yard.shipping_phone
    }
  },
  mounted () {
    if (this.yard) { this.currentInfo() }
  }
}
</script>
