var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.editing)?_c('v-card-subtitle',{staticClass:"ml-1 mt-n3"},[_c('v-row',[_vm._v(" Overview "),_c('base-button',{attrs:{"color":"error","icon-bool":"","small":"","icon":"mdi-close-circle-outline"},on:{"clicked":function($event){return _vm.$emit('cancel')}}})],1)],1):_vm._e(),_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"caption"},[_vm._v(" * Required ")]),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Yard Name *","error-messages":errors},model:{value:(_vm.yardName),callback:function ($$v) {_vm.yardName=$$v},expression:"yardName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 6 : 3}},[_c('v-select',{attrs:{"items":_vm.configCurrencies,"loading":_vm.configLoading,"item-value":"value","item-text":"text","label":"Currency"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 6 : 3}},[_c('v-checkbox',{attrs:{"label":"Has dock"},model:{value:(_vm.dock),callback:function ($$v) {_vm.dock=$$v},expression:"dock"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('paginated-autocomplete',{attrs:{"label":"Select a Region","item-text":"name","item-value":"id","base-url":"/api/regions/"},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Select a Status *","items":_vm.statuses,"item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('paginated-autocomplete',{attrs:{"label":"Select a PPM Calculator","item-text":"name","item-value":"id","base-url":"/api/ppm_calculations/"},model:{value:(_vm.ppmCalculator),callback:function ($$v) {_vm.ppmCalculator=$$v},expression:"ppmCalculator"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('v-text-field',{attrs:{"label":"Street"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('v-text-field',{attrs:{"label":"City"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"State Abbreviation","error-messages":errors},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('v-text-field',{attrs:{"label":"Zip Code"},model:{value:(_vm.zip),callback:function ($$v) {_vm.zip=$$v},expression:"zip"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Notes","rows":2,"counter":true,"auto-grow":"","maxlength":"280"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('base-button',{attrs:{"auto-center":"","loading":_vm.addingInfo,"disabled":invalid,"label":"Submit"},on:{"clicked":_vm.addYardInfo}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }