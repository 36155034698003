<template>
  <v-dialog
    v-model="callNotesDialog"
    persistent
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        small
        color="primary"
        v-if="$store.getters.can('create-call-notes')"
      >
        New Call Note
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Create a Call Note
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <base-button
            @clicked="callNotesDialog = false"
            other-style-value="text-right"
            icon-bool
            right
            icon="mdi-close"
          />
        </v-toolbar-items>
      </v-toolbar>
      <ValidationObserver
        v-slot="{ invalid }"
        ref="validationObserver"
      >
        <v-card-text>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="subject"
              label="Subject"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-textarea
              v-model="noteBody"
              label="Body"
              :error-messages="errors"
            />
          </ValidationProvider>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="invalid"
              color="primary"
              text
              class="text-right"
              @click="createNote"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'WriteCallNotes',
  data: () => ({
    callNotesDialog: false,
    subject: null,
    noteBody: null
  }),
  props: { id: { type: Number, required: true } },
  methods: {
    createNote () {
      axios.post('/api/call_notes/', { yard_id: this.id, body: this.noteBody, subject: this.subject }).then(() => {
        this.$emit('refetchYard')
        this.callNotesDialog = false
      })
    }
  }
}
</script>
