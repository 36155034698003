<template>
  <v-card>
    <v-card-subtitle v-if="!editingInfo">
      <v-row class="pl-4">
        Contacts
        <base-button
          color="info"
          icon-bool
          x-small
          @clicked="editingInfo = true"
          v-if="$store.getters.can('edit-yards')"
          icon="mdi-pencil"
        />
      </v-row>
    </v-card-subtitle>
    <div
      class="ml-4 mt-n4"
      v-if="!editingInfo"
    >
      <v-card-subtitle class="ml-n2 font-weight-bold">
        Primary Contact
      </v-card-subtitle>
      <v-row>
        <card-list-item
          title="Name"
          breakpoint="mdAndDown"
          :breakpoint-col-val="12"
          :other-col-val="6"
        >
          {{ yard.contact | filterNA }}
        </card-list-item>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="12"
          :other-col-val="6"
          title="Phone"
        >
          {{ yard.phone | filterNA }}
        </card-list-item>
      </v-row>
      <card-list-item
        no-cols
        title="E-mail"
      >
        {{ yard.email | filterNA }}
      </card-list-item>
      <v-card-subtitle class="ml-n2 font-weight-bold">
        Shipping Contact
      </v-card-subtitle>
      <v-row>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="12"
          :other-col-val="6"
          title="Name"
        >
          {{ yard.shipping_contact | filterNA }}
        </card-list-item>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="12"
          :other-col-val="6"
          title="Phone"
        >
          {{ yard.shipping_phone | filterNA }}
        </card-list-item>
      </v-row>
      <card-list-item
        no-cols
        title="E-mail"
      >
        {{ yard.shipping_email | filterNA }}
      </card-list-item>
    </div>
    <write-contact-info
      v-else
      :yard="yard"
      :editing="true"
      @cancel="editingInfo = false"
      @addContactInfo="addContactInfo"
    />
  </v-card>
</template>

<script>
import WriteContactInfo from '../Write/WriteContactInfo'

export default {
  name: 'ContactInfo',
  props: { yard: { type: Object, required: true } },
  components: { WriteContactInfo },
  data: () => ({ editingInfo: false }),
  methods: {
    // writecontactinfo -> createyard or yard
    addContactInfo (params) {
      this.editingInfo = false
      this.$emit('updateYard', params)
    }
  }
}
</script>
