var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',[_vm._v(" Call Notes ")]),(_vm.callNotes.length > 0)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.callNotes.length)+" "+_vm._s(_vm.callNotes.length > 1 ? 'Notes' : 'Note')+" ")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":[
            {text: 'Date', value: 'created_at'},
            {text: 'Creator', value: 'created_by'},
            {text: 'Subject', value: 'subject'},
            {text: 'Body', value: 'body'}
          ],"items":_vm.callNotes},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.created_by",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by.username)+" ")]}}],null,false,1642581887)})],1)],1)],1):_c('card-list-item',{attrs:{"other-col-val":12,"title":"This yard does not have any call notes"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }